import * as React from "react";
import Layout from "../../components/Layout";

// eslint-disable-next-line
const ContactPage = () => {
  return (
    <Layout>
      <section className="section section--gradient">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="contact-container">
                <div className="contact-column">
                  <div className="is-uppercase has-text-weight-bold">
                    Contact
                  </div>
                  <a href="mailto:ni.tomczyk@gmail.com">ni.tomczyk@gmail.com</a>
                </div>

                <div className="contact-column">
                  <div className="is-uppercase has-text-weight-bold">
                    Social Media
                  </div>
                  <a href="https://www.behance.net/nina_tomczyk">Behance</a>
                  <a href="https://www.linkedin.com/in/nina-tomczyk-b21569255/">
                    LinkedIn
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ContactPage;
